import { axiosInstance } from "../utills";

// Function to fetch user data
export const fetchUsers = async () => {
    try {
        const response = await axiosInstance.get("/get_users");
        const data = response.data
        
        return data.map((item, index) => ({
            Select: item?.id,
            ID: index + 1,
            "Contact Id": item?.contact_id,
            Name: item?.name,
            Phone: item?.phone_number,
            Status: item?.status,
            "Broadcast Date":
                item?.last_message_date === null
                    ? "-"
                    : new Date(item?.last_message_date)
                        .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })
                        .toUpperCase() || "-",
            "Last Activity Date":
                item?.last_activity_date === null
                    ? "-"
                    : new Date(item?.last_activity_date)
                        .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })
                        .toUpperCase() || "-",
            "Created At":
                item?.created_at === null
                    ? "-"
                    : new Date(item?.created_at)
                        .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })
                        .toUpperCase() || "-",
        }));
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
};

// Function to select broadcast
export const selectBroadcast = async (selectedUsers, broadcastName) => {
    try {
        const response = await axiosInstance.post("/select-broadcast", {
            selectedUsers,
            broadcastName,
        });

        return response.data.success;
    } catch (error) {
        console.error("Error selecting broadcast:", error);
        throw error;
    }
};

export const deleteUsers = async (userIds) => {
    const config = {
        method: "post",
        url: "/delete-users",
        data: {
            ids: userIds,
        },
    };

    return axiosInstance.request(config);
};

export const bulkUploadData = async (data) => {
    try {
        const response = await axiosInstance.post("/bulkUpload", data);
        return response; // Only return the response data
    } catch (error) {
        // Throw an appropriate error for the calling component to handle
        if (error.response?.data) {
            throw new Error(error.response.data.error || "Error during bulk upload");
        }
        throw new Error(error.message || "An unknown error occurred");
    }
};