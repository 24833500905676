const LeadModal = ({ closeModal, summary, loading, error }) => {
    return (<div
        style={{
            position: "fixed",
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100%",
            zIndex: 1000,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        onClick={closeModal}
    >
        <div
            style={{
                maxWidth: "1000px",
                maxHeight: "80vh",
                overflowY: "auto",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <h2 style={{ marginBottom: "10px" }}>AI Chat Summary</h2>
            {loading && <p style={{ color: "black" }}>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {!loading && !error && summary && (
                <p
                    style={{
                        color: "black",
                        whiteSpace: "pre-line",
                        fontWeight: 300,
                        lineHeight: 1.5,
                    }}
                >
                    {summary}
                </p>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                    style={{
                        padding: "10px 20px",
                        marginTop: "20px",
                        backgroundColor: "#f0f0f0",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "10px",
                    }}
                    onClick={closeModal}
                >
                    Close
                </button>
            </div>
        </div>
    </div>)
}

export default LeadModal