// ZoomAnalysis.js

import React, { useState, useEffect, useMemo } from 'react';
import { SessionCard } from '../component/SessionCard';
import { SessionDetail } from '../component/SessionDetail';
import { DateRangeFilter } from '../component/DateRangeFilter';
import { RepresentativeFilter } from '../component/RepresentativeFilter';
import { RoleFilter } from '../component/RoleFilter';
import ZoomPagination from '../component/ZoomPagination';
import { fetchSessionData, fetchSessionDataStrategist, normalizeSessionData, normalizeSessionDataStrategist } from '../api/zoomanalysisApi';
import { PerformanceFilter } from '../component/PerformanceFilter';
import { SessionTypeFilter } from '../component/SessionTypeFilter';
import { ScorecardFilters } from '../component/ScoreCardFilters';
import PerformanceMetrics from '../component/PerformanceMetrics';
import { SessionDetailStrategist } from '../component/SessionDetailStrategist';
import { ViewSelector } from '../component/viewSelector';
import PaginationControl from '../component/PaginationControl';

function ZoomAnalysis() {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRepresentative, setSelectedRepresentative] = useState('All Representatives');
  const [selectedRole, setSelectedRole] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionType, setSessionType] = useState('All Sessions')
  const [performaceScore, setPerformaceScore] = useState('All Scores')
  // Pagination state
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;
  const [activeTab, setActiveTab] = useState("sessions");
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
const [currentView, setCurrentView] = useState('grid'); // Default to grid view

  // Fetch data from the API
  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchSessionData();
        const strategistData = await fetchSessionDataStrategist(); 
        const normalizedData = normalizeSessionData(data,"Specialist");
        const normalizedDataSpecialist = normalizeSessionDataStrategist(strategistData,"Strategist");

        setSessions([...normalizedData,...normalizedDataSpecialist]);
        console.log([...normalizedData,...normalizedDataSpecialist])

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  // Filter sessions based on filters
  const filteredSessions = useMemo(() => {
    return sessions.filter((session) => {
      const sessionDate = new Date(session.date);
      const matchesDate =
        (!startDate || sessionDate >= new Date(startDate)) &&
        (!endDate || sessionDate <= new Date(endDate));

      const matchesRepresentative =
        selectedRepresentative === 'All Representatives' ||
        (session.representative.name &&
          session.representative.name === selectedRepresentative);

      const matchesRole =
        selectedRole === 'All' ||
        (session.representative.role &&
          session.representative.role === selectedRole);

          const matchesRating = (() => {
            if (performaceScore === 'All Scores') return true;
            if (performaceScore === 'From 4 to 5') return session.rating <= 5 && session.rating >= 4;
            if (performaceScore === 'From 3 to 4') return session.rating <= 4 && session.rating >= 3;
            if (performaceScore === 'Over 3') return session.rating >= 3;
            if (performaceScore === 'Under 3') return session.rating < 3;
            return true;
          })();

      // const matchesSessionType =
      // sessionType === 'All Sessions' ||
      // (session.rating &&
      //   session.rating === selectedRepresentative);

      return matchesDate && matchesRepresentative && matchesRole && matchesRating;
    }).sort((a, b) => new Date(b.date) - new Date(a.date)); 

  }, [sessions, startDate, endDate, selectedRepresentative, selectedRole, performaceScore]);

  const totalItems = filteredSessions.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentSessions = useMemo(() => {
    const indexOfLastSession = currentPage * itemsPerPage;
    const indexOfFirstSession = indexOfLastSession - itemsPerPage;
    return filteredSessions.slice(indexOfFirstSession, indexOfLastSession);
  }, [filteredSessions, currentPage, itemsPerPage]);

  const currentSession = sessions.find((s) => s.id === selectedSession);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const representatives = useMemo(() => {
    const repsSet = new Set(sessions.map((s) => s.representative.name).filter((name) => name));
    const reps = Array.from(repsSet);
    return ['All Representatives', ...reps];
  }, [sessions]);


  const ratings = useMemo(() => {
    return [
      'All Scores',
      'From 4 to 5',
      'From 3 to 4',
      'Over 3',
      'Under 3',
    ];
  }, []);


  const roles = ['All', 'Strategist', 'Specialist'];

  const filteredRepresentatives = useMemo(() => {
    const repsSet = new Set(
      sessions
        .filter(
          (session) =>
            selectedRole === 'All' || session.representative.role === selectedRole
        )
        .map((session) => session.representative.name)
        .filter((name) => name)
    );
  
    return ['All Representatives', ...Array.from(repsSet)];
  }, [sessions, selectedRole]);

  const filteredRoles = useMemo(() => {
    const repsSet = new Set(
      sessions
        .filter(
          (session) =>
            selectedRepresentative === 'All Representatives' || session.representative.name === selectedRepresentative
        )
        .map((session) => session.representative.role)
        .filter((role) => role)
    );
  
    return ['All', ...Array.from(repsSet)];
  }, [sessions, selectedRepresentative]);

  const [colClass, setColClass] = useState("col-md-3");
          
            useEffect(() => {
              const handleResize = () => {
                if (window.innerWidth < 990) {
                  setColClass("col-md-6 mt-1");
                } else {
                  setColClass("col-md-3");
                }
              };
          
              window.addEventListener("resize", handleResize);
              handleResize(); // Trigger initially
          
              return () => {
                window.removeEventListener("resize", handleResize);
              };
            }, []);
  return (
    <div className="container-fluid py-4">
      {!selectedSession ? (
        <>
          {/* <h1 onClick={(e) => setActiveTab("sessions")}> Sessions</h1>
          <h1 onClick={(e) => setActiveTab("scorecard")}> Scorecard</h1> */}

          {activeTab === "sessions" ? (
            <>
              <div className="row g-3">
                <div className={`${colClass}`}>
                  <DateRangeFilter
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={(date) => {
                      setStartDate(date);
                      setCurrentPage(1);
                    }}
                    onEndDateChange={(date) => {
                      setEndDate(date);
                      setCurrentPage(1);
                    }}
                  />
                </div>
                <div className={`${colClass}`}>
                  <RoleFilter
                    roles={filteredRoles}
                    selectedRole={selectedRole}
                    onRoleChange={(role) => {
                      setSelectedRole(role);
                      setCurrentPage(1);
                    }}
                  />
                </div>
                <div className={`${colClass}`}>
                  <RepresentativeFilter
                    representatives={filteredRepresentatives}
                    selectedRepresentative={selectedRepresentative}
                    onRepresentativeChange={(rep) => {
                      setSelectedRepresentative(rep);
                      setCurrentPage(1);
                    }}
                  />
                </div>
             
                <div className={`${colClass}`}>
                  <PerformanceFilter
                    ratings={ratings}
                    performaceScore={performaceScore}
                    onPerformaceScoreChange={(rate) => {
                      setPerformaceScore(rate);
                      setCurrentPage(1);
                    }}
                  />
                </div>
                {/* <div className="col-md-3">
                  <SessionTypeFilter
                  />
                </div> */}
              </div>

              {loading ? (
                <div className="text-center">Loading...</div>
              ) : error ? (
                <div className="alert alert-danger">Error: {error}</div>
              ) : (
                <>
   
               <div className="mb-3" style={{ overflow: 'hidden' }}>
               
  <div style={{ float: 'right' }}>
    <ViewSelector
      currentView={currentView}
      onViewChange={setCurrentView}
    />
  </div>
</div>


    <div className={`row g-3 ${currentView === 'list' ? 'flex-column' : ''}`}>
      {currentSessions.map((session) => (
        <div className={currentView === 'grid' ? 'col-md-6' : 'col-12'} key={session.id}>
          <SessionCard
  session={session}
  onClick={setSelectedSession}
  viewType={currentView}
/>

        </div>
      ))}
      <div>

<PaginationControl
currentPage={currentPage}
totalPages={totalPages}
tempPage={tempPage}
setCurrentPage={setCurrentPage}
updateTempPage={updateTempPage}
itemsPerPage={itemsPerPage}
setItemsPerPage={setItemsPerPage}
/>
</div>
    </div>
  
    {/* Use Here */}
                  {/* <ZoomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  /> */}
                </>
              )}
            </>
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ScorecardFilters
                    fromDate={startDate}
                    toDate={endDate}
                    selectedRepresentative={representatives}
                    selectedRole={selectedRole}
                    selectedRepresentatives={selectedRepresentative}
                    onStartDateChange={(date) => {
                      setStartDate(date);
                      setCurrentPage(1);
                    }}
                    onToDateChange={(date) => {
                      setEndDate(date + 1);
                      setCurrentPage(1);
                    }}
                    onRepresentativeChange={(rep) => {
                      setSelectedRepresentative(rep);
                      setCurrentPage(1);
                    }}
                    onRoleChange={(role) => {
                      setSelectedRole(role);
                      setCurrentPage(1);
                    }}
                    onRepresentativesChange={(rep) => {
                      setSelectedRepresentative(rep);
                      setCurrentPage(1);
                    }}
                    representatives={representatives}
                    roles={roles}
                  />
                  {/* <PerformanceMetrics /> */}
                </div>
              </div>
            </div>
          )}
        </>
      ) : currentSession && currentSession.representative.role === "Specialist" ? (
        <SessionDetail
          session={currentSession}
          onBack={() => setSelectedSession(null)}
        />
      ) : (<SessionDetailStrategist session={currentSession} onBack={() => setSelectedSession(null)}/>)}
    </div>
  );
}

export default ZoomAnalysis;
