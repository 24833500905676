const DownloadReport = () => {
    const handleDownload = () => {
        window.location.href = "https://api.kuru.ai/download-kpi-stats";
    };
    return (
        <div className="col-md-4 offset-md-4" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <button
                onClick={() => handleDownload()}
                className="btn btn-sm"
                style={{
                    backgroundColor: "rgb(31, 154, 214)",
                    borderRadius: 5,
                    color: "white",
                    marginRight: 5
                }}
            >
                Download Report
            </button>
        </div>
    )
}

export default DownloadReport