// blogApi.js
import { axiosInstance } from "../utills";

// Fetch all posts
export const fetchAllPosts = async () => {
    const config = {
        method: "get",
        url: "/dashboard/fetch-blog",
    };

    try {
        const response = await axiosInstance.request(config);
        const { data: { code, success, data } } = response;
        if (success && code === 200) {
            return data;
        } else {
            return []; // Return empty array for unsuccessful responses

            // throw new Error("Failed to fetch posts");

        }
    } catch (error) {
        console.error(error);
        // throw new Error("Error fetching posts");
        return []; // Return empty array for unsuccessful responses

    }
};

// Create a new blog post
export const createBlogPost = async (title, content, media) => {
    const data = new FormData();
    data.append("text", content);
    data.append("title", title);
    data.append("blogImage", media);

    const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/dashboard/create-blog",
        data: data,
    };

    try {
        const response = await axiosInstance.request(config);
        const { data: { code, success, message } } = response;
        if (success && code === 200) {
            return message;
        } else {
            throw new Error(message);
        }
    } catch (error) {
        console.error(error);
        throw new Error("Error creating blog post");
    }
};
