import "../ui-components/Table.css"

const LeadTable = ({
    headerCheckboxRef,
    selectedLeads,
    filteredLeads,
    handleSelectAll,
    paginatedData,
    currentPage,
    itemsPerPage,
    formatDate,
    handleCheckboxChange,
    handleLeadStatusChange,
    getStatusColor,
    isUpdating,
    handleViewSummary,
    notes,
    handleNoteChange,
    handleSaveNote,
    isSaving,
}) => {
    return (
        <div style={{ overflowX: "auto" }}>

        <table className="table">
            <thead>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            ref={headerCheckboxRef}
                            checked={
                                selectedLeads.length > 0 &&
                                selectedLeads.length === filteredLeads.length
                            }
                            onChange={handleSelectAll}
                        />
                    </th>
                    <th>SNo</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Appointment Date</th>
                    <th>Lead Status</th>
                    <th>AI Chat Summary</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody>
                {paginatedData.map((lead, index) => (
                    <tr key={lead.id}>
                        <td>
                            <input
                                type="checkbox"
                                checked={selectedLeads.includes(lead.id)}
                                onChange={() => handleCheckboxChange(lead.id)}
                            />
                        </td>
                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>{lead.name}</td>
                        <td>{lead.phone_number}</td>
                        <td>{formatDate(lead.appointment_date)}</td>
                        <td>
                            <select
                                value={lead.lead_status || "Pending"} // Default to "Pending" if lead_status is not set
                                onChange={(e) =>
                                    handleLeadStatusChange(lead.id, e.target.value)
                                }
                                style={{
                                    backgroundColor: getStatusColor(lead.lead_status),
                                    borderColor: getStatusColor(lead.lead_status),
                                    color: "white",
                                }}
                                className="form-control form-control-sm"
                            >
                                <option
                                    value="Pending"
                                    style={{ backgroundColor: "#FFA500", color: "white" }}
                                >
                                    Pending
                                </option>
                                <option
                                    value="Rescheduled"
                                    style={{ backgroundColor: "#FF6347", color: "white" }}
                                >
                                    Rescheduled
                                </option>
                                <option
                                    value="Completed"
                                    style={{ backgroundColor: "#04AA6D", color: "white" }}
                                >
                                    Completed
                                </option>
                            </select>
                            {isUpdating === lead.id && (
                                <span className="spinner-border spinner-border-sm ml-2"></span>
                            )}
                        </td>
                        <td>
                            <button
                                onClick={() => handleViewSummary(lead)}
                                className="btn btn-sm"
                                style={{ backgroundColor: "rgb(31, 154, 214)", color: "white" }}
                            >
                                View Summary
                            </button>
                        </td>
                        <td style={{ display: "flex", flexDirection: "row" }}>
                            <input
                                type="text"
                                name={`note_${lead.id}`}
                                value={notes[lead.id] || ""}
                                onChange={(e) => handleNoteChange(e, lead.id)}
                                className="form-control form-control-sm"
                                style={{ flex: 1 }}
                            />
                            <div style={{ width: 15 }}></div>
                            <button
                                onClick={() => handleSaveNote(lead.id)}
                                className="btn btn-success btn-sm"
                                disabled={isSaving[lead.id] || false}
                            >
                                {isSaving[lead.id] ? (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    )
}

export default LeadTable