import React, { useState, useEffect } from "react";
import ChatList from "../component/inbox-components/ChatList";
import ChatBox from "../component/inbox-components/ChatBox";

import useSocket from "../hooks/useSocket";

function Inbox() {
  const [selectedChat, updateSelectedChat] = useState({ type: null, index: 0 });
  const [chatList, updateChatList] = useState([]);
  const [broadcastChatList, updateBroadcastChatList] = useState([]);

  const trigger = useSocket()

  useEffect(() => {
    console.log("Triggered Message Is", trigger);
  }, [trigger]);

  return (
    <div className="row">
      <div className="cont">
        <ChatList
          selectedChat={selectedChat}
          updateSelectedChat={updateSelectedChat}
          chatList={chatList}
          updateChatList={updateChatList}
          broadcastChatList={broadcastChatList}
          updateBroadcastChatList={updateBroadcastChatList}
          trigger={trigger}
        />
        <ChatBox
          selectedChat={selectedChat}
          chatList={chatList}
          broadcastChatList={broadcastChatList}
          trigger={trigger}
        />
      </div>
    </div>
  );
}

export default Inbox;
