const BroadcastUser = ({
    broadcastName,
    setBroadcastName,
    setShowModal,
    getUsers
}) => {
    return (
        <div
            style={{
                position: "absolute",
                background: "rgba(0,0,0,0.2)",
                width: "100%",
                height: "100%",
                zIndex: 10,
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            onClick={() => {
                setShowModal(false);
                setBroadcastName("");
            }}
        >
            <div
                style={{
                    width: "600px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <h2 style={{ marginBottom: "10px" }}>Broadcast Settings</h2>
                <p
                    style={{ marginBottom: "20px", color: "black", fontWeight: 300 }}
                >
                    You are about to create a broadcast channel with the selected
                    users. Please provide a name for the channel before proceeding.
                </p>
                <input
                    type="text"
                    placeholder="Broadcast channel name"
                    value={broadcastName}
                    onChange={(e) => setBroadcastName(e.target.value)} // Update broadcast name
                    maxLength={25}
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        style={{
                            padding: "10px 20px",
                            marginRight: "10px",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setShowModal(false);
                            setBroadcastName("");
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "#007BFF",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (broadcastName.trim() !== "") {
                                getUsers(broadcastName);
                                setShowModal(false);
                            }
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BroadcastUser