const PasswordToggle = ({ showPassword, setShowPassword, password, setPassword }) => {
    return (
        <div className="position-relative">
            <input
                type={showPassword ? "text" : "password"}
                className="form-control form-control-lg"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                type="button"
                className="btn position-absolute end-0 top-50 translate-middle-y border-0"
                style={{ backgroundColor: "transparent" }}
                onClick={() => setShowPassword(!showPassword)}
            >
                <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}></i>
            </button>
        </div>
    );
};

export default PasswordToggle