import React, { useEffect, useState } from "react";


import { Outlet, Link } from "react-router-dom";
import SidebarDesktop from "../../component/sidebar-components/SidebarDesktop";
import SidebarMobile from "../../component/sidebar-components/SidebarMobile";
import TopNav from "../../component/sidebar-components/TopNav";
import styles from "./Root.module.css"
export default function Root() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false); // Ensure menu is closed on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (
    <>
      <div
        className={styles.mainContainer}
      >
        {/* Side Menu for Desktop */}
        <SidebarDesktop screenWidth={screenWidth} />

        {/* Mobile Menu Overlay */}
        {isMenuOpen && (
          <SidebarMobile setIsMenuOpen={setIsMenuOpen} />

        )}

        {/* Main Section */}
        <div
          className={`container-fluid ${styles.mainSection} main-section`}
          style={{ backgroundColor: "#f2f4f6" }}
        >
          <div
            className="row"
            style={{ backgroundColor: "#fafafa", minHeight: "100vh" }}
          >
            <div className="container-fluid" style={{ paddingTop: 87 }}>
              <TopNav toggleMenu={toggleMenu} />
              <Outlet />
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
