const Logo = () => (
    <div className="logo-container mb-3">
        <svg width="240" height="50" viewBox="0 0 240 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="10" y="8" width="36" height="6" fill="#0288D1"></rect>
            <rect x="10" y="22" width="36" height="6" fill="#0288D1"></rect>
            <rect x="10" y="36" width="36" height="6" fill="#0288D1"></rect>
            <text x="58" y="30" fill="#0288D1" style={{ fontSize: "28px", fontWeight: "bold", fontFamily: "Arial" }}>
                FREEDOM
            </text>
            <text x="58" y="42" fill="#0288D1" style={{ fontSize: "11px", letterSpacing: "0.5px", fontFamily: "Arial" }}>
                PROPERTY INVESTORS
            </text>
        </svg>
    </div>
);

export default Logo