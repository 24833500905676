import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import styles from "./Charts.module.css"
import "../ui-components/Card.css"

const ResponseTimeChart = ({ responseTimeData }) => {
     const [colClass, setColClass] = useState("col-md-4");
    
      useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1284 && window.innerWidth >= 996) {
                setColClass("col-md-6");
              } else if(window.innerWidth < 996){
                setColClass("col-md-12");
              } else {
            setColClass("col-md-4");
          }
        };
    
        window.addEventListener("resize", handleResize);
        handleResize(); // Trigger initially
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    return (
        <div
            className={`${colClass} ${styles.container}`}

        >
            <div
                className={`dashboard-card ${styles.card}`}
            >
                <h5 className={` ${styles.marginBottom} ${styles.heading}`}>Response Time Distribution</h5>
                <div
                    className={` ${styles.flexContainerSecondary}`}
                >
                    <Bar
                        data={{
                            labels: responseTimeData.map(({ range }) => range),
                            datasets: [
                                {
                                    label: "User Count",
                                    data: responseTimeData.map(({ count }) => count),
                                    backgroundColor: "rgba(153, 102, 255, 0.5)",
                                    borderColor: "rgba(153, 102, 255, 1)",
                                    borderWidth: 1,
                                    // To make it resemble a histogram, set bar thickness and remove spacing
                                    barPercentage: 1.0,
                                    categoryPercentage: 1.0,
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: "Response Time Range",
                                    },
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: "Count",
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: true,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false, // Hide legend since each bar represents a range
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            const label = context.label || "";
                                            const value = context.parsed.y;
                                            return `${label}: ${value}`;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default ResponseTimeChart