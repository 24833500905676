import React from 'react';

const CriterionCard = ({ criterionId, title, isDone, rating, comments, strengths, areasForImprovement }) => {
  return (
    <div className="card mb-4 shadow-sm" id={criterionId}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="h4 fw-bold text-dark mb-0">{title}</h2>
          <div className="d-flex align-items-center">
            <span className={`badge me-2 ${isDone ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}`}>
              {isDone ? 'Done' : 'Not Done'}
            </span>
            <span className="badge bg-primary-subtle text-primary">{rating}/5</span>
          </div>
        </div>

        <div className="mb-3">
          <h5 className="fw-bold text-dark mb-2 fs-6">Analysis</h5>
          <p className="text-dark mb-0 small"   style={{ lineHeight: '1.5', color: 'black', fontWeight: 'normal' }}
>{comments || "No comments"}</p>
        </div>

        <div className="mb-3 p-3 rounded" style={{ backgroundColor: 'rgba(25, 135, 84, 0.1)', borderLeft: '4px solid rgba(25, 135, 84, 0.6)' }}>
          <h5 className="fw-bold mb-2 fs-6" style={{ color: 'rgb(20, 108, 67)' }}>Strengths</h5>
          <p className="text-dark mb-0 small"   style={{ lineHeight: '1.5', color: 'black', fontWeight: 'normal' }}
>{strengths || "No Strength"}</p>
        </div>

        <div className="p-3 rounded" style={{ backgroundColor: 'rgba(255, 193, 7, 0.1)', borderLeft: '4px solid rgba(255, 193, 7, 0.6)' }}>
          <h5 className="fw-bold mb-2 fs-6" style={{ color: 'rgb(133, 100, 4)' }}>Areas for Improvement</h5>
          <p className="text-dark mb-0 small"   style={{ lineHeight: '1.5', color: 'black', fontWeight: 'normal' }}
>{areasForImprovement || "No areas for Improvement"}</p>
        </div>

        {/* {showTimestamps && timestamps && Object.keys(timestamps).length > 0 && (
          <div className="mt-3 pt-3 border-top">
            <h6 className="fw-bold text-dark mb-2 small">Key Timestamps</h6>
            <div className="d-flex flex-wrap gap-2">
              {Object.entries(timestamps).map(([label, time]) => (
                <div key={label} className="badge bg-light text-dark p-2 small">
                  {label}: <span className="text-primary">{time}</span>
                </div>
              ))}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CriterionCard;
