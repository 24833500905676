// RepresentativeFilter.js
import React from "react";

import { UserCircle, Users } from "lucide-react";

export function RepresentativeFilter({
  representatives,
  selectedRepresentative,
  onRepresentativeChange,
}) {
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-body">
        <div className="d-flex align-items-center mb-3">
          <Users className="me-2 text-primary" size={20} />

          &nbsp;
          <label htmlFor="representative" style={{ fontFamily: "system-ui" }}>Representative</label>
        </div>
        <select
          id="representative"
          className="form-select"
          value={selectedRepresentative}
          onChange={(e) => onRepresentativeChange(e.target.value)}
          style={{ fontFamily: "system-ui" }}
        >
          {representatives.map((rep) => (
            <option key={rep} value={rep}>
              {rep}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
