// File: /components/charts/LeadStatusChart.js
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import styles from "./Charts.module.css"
import "../ui-components/Card.css"

const LeadStatusChart = ({ leadStatusData, colorMapping }) => {
     const [colClass, setColClass] = useState("col-md-4");
        
          useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth < 1284 && window.innerWidth >= 996) {
                    setColClass("col-md-6");
                  } else if(window.innerWidth < 996){
                    setColClass("col-md-12");
                  } else {
                setColClass("col-md-4");
              }
            };
        
            window.addEventListener("resize", handleResize);
            handleResize(); // Trigger initially
        
            return () => {
              window.removeEventListener("resize", handleResize);
            };
          }, []);
    return (
        <div
            className={`${colClass} ${styles.container}`}

        >
            <div
                className={`dashboard-card ${styles.card}`}
            >
                <h5 className={`${styles.marginBottom} ${styles.heading}`}>Lead Status Distribution</h5>
                <div
                    className={` ${styles.flexContainerSecondary}`}
                >
                    <Pie
                        data={{
                            labels: leadStatusData.map(({ lead_status }) => lead_status),
                            datasets: [
                                {
                                    data: leadStatusData.map(({ user_count }) => user_count),
                                    backgroundColor: leadStatusData.map(
                                        (item) => colorMapping[item.lead_status] || "#4BC0C0" // Default color if lead_status not in mapping
                                    ),
                                    hoverBackgroundColor: leadStatusData.map(
                                        (item) => colorMapping[item.lead_status] || "#4BC0C0"
                                    ),
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "right", // Legend on the right
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            const label = context.label || "";
                                            const value = context.parsed;
                                            return `${label}: ${value}`;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default LeadStatusChart;