import React from "react";

import { Star, UserCircle } from "lucide-react";

export function PerformanceFilter({
    ratings,
    performaceScore,
    onPerformaceScoreChange,
}) {
    return (
        <div className="card shadow-sm mb-3">
            <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                    <Star className="me-2 text-warning" size={20} />

                    &nbsp;
                    <label htmlFor="performanceScore" style={{ fontFamily: "system-ui"   }}> Score</label>
                </div>
                <select
                    id="performanceScore"
                    className="form-select"
                    value={performaceScore}
                    onChange={(e) => onPerformaceScoreChange(e.target.value)}
                    style={{ fontFamily: "system-ui" }}
                >
                    {ratings.map((rate) => (
                        <option key={rate} value={rate}>
                            {rate}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}
