import React from 'react';
import { LayoutGrid, List } from 'lucide-react';

export const ViewSelector = ({ currentView, onViewChange }) => {
  return (
    <div className="btn-group">
      <button
        type="button"
        className={`btn ${currentView === 'grid' ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => onViewChange('grid')}
        title="Grid View"
      >
        <LayoutGrid size={16} />
      </button>
      <button
        type="button"
        className={`btn ${currentView === 'list' ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => onViewChange('list')}
        title="List View"
      >
        <List size={16} />
      </button>
    </div>
  );
};
