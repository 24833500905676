import React from "react";
import Facebook from "../../assets/facebook.png";
import Google from "../../assets/google.png";
import Instagram from "../../assets/instagram.png";
import Whatsapp from "../../assets/whatsapp.png";
import Sms from "../../assets/sms.png";
import styles from './Settings.module.css'; // Importing the CSS module

const icons = [
  { name: "Facebook", icon: Facebook },
  { name: "Google", icon: Google },
  { name: "Instagram", icon: Instagram },
  { name: "Whatsapp", icon: Whatsapp },
  { name: "Sms", icon: Sms },
];

function Settings() {
  return (
    <>
      {icons.map((icon, index) => (
        <div className={`row ${styles.container}`} >
          <div
            key={index}
            className={`col-md-5 ${styles.iconContainer}`}

          >
            <img
              src={icon.icon}
              alt={icon.name}
              className={styles.iconImage}
            />
            <h3 className={styles.iconText}>{icon.name}</h3>
          </div>
        </div>
      ))}
    </>
  );
}

export default Settings;
