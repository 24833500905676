import React, { useState } from "react";
import Upload from "../../assets/upload.png";
import { createBlogPost, fetchAllPosts } from "../../api/blogApi";
import "./Blog.css";
import "../../component/ui-components/icnImage.css"
import "../../component/ui-components/Button.css"
import "../../component/ui-components/textarea.css"

function Blog() {
  const [title, setTitle] = useState("");
  const [media, setMedia] = useState(null);
  const [content, setContent] = useState("");
  const [previousBlogs, setPreviousBlogs] = useState([]);
  const [selectedPreviousBlogId, setSelectedPreviousBlogId] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const maxWordCount = 450;

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMediaChange = (e) => {
    setMedia(e.target.files[0]);
  };

  const handleContentChange = (e) => {
    let text = e.target.value;
    const words = text.trim().split(/\s+/);
    if (words.length > maxWordCount) {
      text = words.slice(0, maxWordCount).join(" ");
    }
    setContent(text);
    setWordCount(words.length);
  };

  const handleSubmit = async () => {
    if (!title || !content) {
      alert("Please fill in all fields");
      return;
    }
    if (wordCount > maxWordCount) {
      alert(`Word count exceeds the maximum limit of ${maxWordCount}`);
      return;
    }

    try {
      const message = await createBlogPost(title, content, media);
      alert(message);
      setTitle("");
      setContent("");
      setMedia(null);
      fetchAllPost();
    } catch (error) {
      alert(error.message);
    }
  };

  const fetchAllPost = async () => {
    try {
      const data = await fetchAllPosts();
      setPreviousBlogs(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePreviousBlogClick = (previousBlog) => {
    setTitle(previousBlog.title);
    setContent(previousBlog.text);
    setSelectedPreviousBlogId(previousBlog._id);
  };

  const handleOnMoveToTrashClick = () => {
    if (selectedPreviousBlogId !== null) {
      alert("Moved to Trash");
      setSelectedPreviousBlogId(null);
      setTitle("");
      setContent("");
    } else {
      alert("Select a post to be moved to trash.");
    }
  };

  const handleClearClick = () => {
    setSelectedPreviousBlogId(null);
    setTitle("");
    setContent("");
  };

  return (
    <div className="main">
      <div className="row scrollView mt-3">
        <div className="col-md-8">
          <p className="sm-title">Add New Post</p>
          <div className="new-post">
            <input
              className="onFocus input-style mb-3 w-100"
              type="text"
              placeholder="Enter Title Here"
              value={title}
              onChange={handleTitleChange}
            />

            <div className="upload-btn input-style mb-3">
              Upload Media
              <input
                type="file"
                name="file-upload"
                onChange={handleMediaChange}
              />
              <img src={Upload} alt="User" className="icn" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {media && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid black",
                    height: 250, // Set the height of the container
                    width: 200, // Set the width of the container
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%", // Ensure the image doesn't exceed the container's height
                      maxWidth: "100%", // Ensure the image doesn't exceed the container's width
                    }}
                    src={URL.createObjectURL(media)}
                    alt="Preview"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "black",
                      padding: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMedia(null);
                    }}
                  >
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      X
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="textarea-count">
              <textarea
                className="onFocus input-style"
                name="textarea"
                placeholder="Enter Content Here"
                value={content}
                onChange={handleContentChange}
              ></textarea>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  paddingRight: "10px",
                }}
              >
                <p className="sm-text">
                  Word Count : {wordCount} / {maxWordCount}
                </p>
                <a
                  className="sm-text"
                  style={{ cursor: "pointer" }}
                  onClick={handleClearClick}
                >
                  Clear
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <p className="sm-title">Publish Information</p>
          <div className="public-info mb-4 ">
            <div className="card">
              <p className="sm-text">
                The content will be published immediately upon clicking the
                <span style={{ color: "#209ad6" }}>"Publish" </span> button.
                Please ensure that you have thoroughly proofread the content
                before it is posted on the platform, as it will be shared with
                all users on the platform.
              </p>
              <p className="sm-text">
                If needed, you may also choose to discard the post by clicking
                the
                <span className="sm-text" style={{ color: "red" }}>
                  "Move To Trash"{" "}
                </span>
                option.
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={handleOnMoveToTrashClick}
                >
                  Move to Trash
                </a>
                <button type="button" className="btn" onClick={handleSubmit}>
                  Publish
                </button>
              </div>
            </div>
          </div>

          <p className="sm-title">Previous Post</p>
          <div className="public-info prev-post">
            <div className="card">
              {previousBlogs.map((previousBlog, index) => (
                <p
                  className="sm-text"
                  onClick={() => handlePreviousBlogClick(previousBlog)}
                >
                  {previousBlog?.title}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
