import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import { Bold, Italic, List, ListOrdered, AlignLeft, AlignCenter, AlignRight } from 'lucide-react';

const RichTextEditor = ({ content, onChange }) => {
    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                bulletList: false,
                orderedList: false,
            }),
            BulletList,
            OrderedList,
            ListItem,
            TextAlign.configure({
                types: ['heading', 'paragraph']
            })
        ],
        content,
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
        editorProps: {
            attributes: {
                class: 'form-control border-0 p-3',
                style: 'min-height: 200px; outline: none; cursor: text'
            }
        }
    });

    if (!editor) {
        return null;
    }

    return (
        <div className="rich-text-editor">
            <div className="border-bottom pb-2 mb-2 d-flex gap-2">
                <div className="btn-group me-2">
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive('bold') ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        title="Bold"
                    >
                        <Bold size={16} />
                    </button>
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive('italic') ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        title="Italic"
                    >
                        <Italic size={16} />
                    </button>
                </div>

                <div className="btn-group me-2">
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive('bulletList') ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        title="Bullet List"
                    >
                        <List size={16} />
                    </button>
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive('orderedList') ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        title="Numbered List"
                    >
                        <ListOrdered size={16} />
                    </button>
                </div>

                <div className="btn-group me-2">
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive({ textAlign: 'left' }) ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().setTextAlign('left').run()}
                        title="Align Left"
                    >
                        <AlignLeft size={16} />
                    </button>
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive({ textAlign: 'center' }) ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().setTextAlign('center').run()}
                        title="Align Center"
                    >
                        <AlignCenter size={16} />
                    </button>
                    <button
                        type="button"
                        className={`btn btn-sm ${editor.isActive({ textAlign: 'right' }) ? 'btn-primary' : 'btn-light'}`}
                        onClick={() => editor.chain().focus().setTextAlign('right').run()}
                        title="Align Right"
                    >
                        <AlignRight size={16} />
                    </button>
                </div>
            </div>

            <style>
                {`
          .ProseMirror {
            min-height: 200px;
            padding: 1rem;
          }
          .ProseMirror:focus {
            outline: none;
          }
          .ProseMirror ul {
            padding-left: 1.5rem;
            list-style-type: disc;
          }
          .ProseMirror ol {
            padding-left: 1.5rem;
            list-style-type: decimal;
          }
          .ProseMirror li {
            margin-bottom: 0.5rem;
          }
            .tiptap.ProseMirror p {
    color: black !important; /* Force black for paragraphs inside the editor */
        font-weight: normal !important; /* Ensure font-weight is normal */

}

        `}
            </style>

            <EditorContent editor={editor} />
        </div>
    );
};

export default RichTextEditor;
