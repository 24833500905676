// File: /components/charts/AppointmentTrendsChart.js
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "./Charts.module.css"
import "../ui-components/Card.css"
const AppointmentTrendsChart = ({ appointmentTrendsData, appointmentPeriod, handleAppointmentPeriodChange }) => {
    const [colClass, setColClass] = useState("col-md-4");

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth < 1284 && window.innerWidth >= 996) {
            setColClass("col-md-6");
          } else if(window.innerWidth < 996){
            setColClass("col-md-12");
          } else {
        setColClass("col-md-4");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    return (
        <div
            className={`${colClass} ${styles.container}`}

        >
            <div
                className={`dashboard-card ${styles.card}`}
            >
                <div
                    className={` ${styles.flexContainerPrimary}`}

                >
                    <h5 className={` ${styles.heading}`}>Appointment Trends</h5>
                    <select
                        className="form-control"
                        style={{ width: "auto" }}
                        value={appointmentPeriod}
                        onChange={handleAppointmentPeriodChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <Line
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Period",
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: "Appointment Count",
                                },
                            },
                        },
                    }}
                    data={{
                        labels: appointmentTrendsData.map(({ period }) => period),
                        datasets: [
                            {
                                fill: true,
                                label: "Appointments",
                                data: appointmentTrendsData.map(
                                    ({ appointment_count }) => appointment_count
                                ),
                                borderColor: "rgb(75, 192, 192)",
                                backgroundColor: "rgba(75, 192, 192, 0.5)",
                            },
                        ],
                    }}
                />
            </div>
        </div>
    );
};

export default AppointmentTrendsChart;