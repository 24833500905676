import { useState, useEffect } from "react";

function usePagination(data, currentPage, itemsPerPage, setStartIndex, setEndIndex, setPaginatedData, setTotalPages) {

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const paginated = data.slice(start, end);
        const total = Math.ceil(data.length / itemsPerPage);

        setPaginatedData(paginated);
        setTotalPages(total);
        setStartIndex(start);
        setEndIndex(end);
    }, [data, currentPage, itemsPerPage, setStartIndex, setEndIndex, setPaginatedData, setTotalPages]);

}

export default usePagination;
