import { axiosInstance } from "../utills";

export const getChatApi = async (id, type) => {
    const config = {
        method: "get",
        url:
            type === "solo"
                ? `/get-chat?phone_number=${id}`
                : type === "broadcast"
                    ? `/get-broadcast-chat?broadcastId=${id}`
                    : ``,
    };

    try {
        const response = await axiosInstance.request(config);
        return {
            messages: type === "solo" ? response.data.messages : response.data,
            channelName: response.data?.channelName,
        };
    } catch (error) {
        console.error("Error fetching chat:", error);
        throw error;
    }
};

export const sendMessageApi = async (phoneNumber, broadcastId, message) => {
    const config = {
        method: "get",
        url: `/send-message?phone_number=${phoneNumber ? encodeURIComponent(phoneNumber) : null
            }&broadcastId=${broadcastId ? broadcastId : null}&message=${message}&sender=system`,
    };

    try {
        const response = await axiosInstance.request(config);
        return response.data;
    } catch (error) {
        console.error("Error sending message:", error);
        throw error;
    }
};

export const getUserInfoApi = async (broadcastId) => {
    const config = {
        method: "get",
        url: `/users-data?broadcastId=${broadcastId}`,
    };

    try {
        const response = await axiosInstance.request(config);
        return response.data;
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const updateBlockStatusApi = async (phoneNumber) => {
    const config = {
        method: "post",
        url: "/change-block-status",
        data: { phone_number: phoneNumber },
    };

    try {
        const response = await axiosInstance.request(config);
        return response
    } catch (error) {
        console.error("Error updating block status:", error);
        throw error;
    }
};
