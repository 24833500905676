import React, { useState } from 'react';
import { MessageSquare, X } from 'lucide-react';
import RichTextEditor from './RichTextEditor';
// import { RichTextEditor } from './RichTextEditor';
// import { sendEmail } from './EmailService';

const FeedbackButton = ({ strategistEmail, sessionTitle }) => {
    const [showModal, setShowModal] = useState(false);
    const [sending, setSending] = useState(false);
    const [formData, setFormData] = useState({
        to: strategistEmail,
        cc: '',
        bcc: '',
        subject: `Feedback for ${sessionTitle}`,
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMessageChange = (content) => {
        setFormData(prev => ({
            ...prev,
            message: content
        }));
    };

    //   const handleSendFeedback = async () => {
    //     try {
    //       setSending(true);
    //       await sendEmail(
    //         formData.to,
    //         formData.subject,
    //         formData.message,
    //         formData.cc,
    //         formData.bcc
    //       );
    //       setShowModal(false);
    //       alert('Feedback sent successfully!');
    //     } catch (error) {
    //       alert('Failed to send feedback. Please try again.');
    //     } finally {
    //       setSending(false);
    //     }
    //   };

    return (
        <>
            <button
                className="btn btn-primary d-flex align-items-center"
                onClick={() => setShowModal(true)}
            >
                <MessageSquare size={16} className="me-2" />
                Give Feedback
            </button>

            {showModal && (
                <>
                    <div className="modal fade show" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content" style={{ borderRadius: '8px', boxShadow: '0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15)' }}>
                                <div className="modal-header py-2 px-3" style={{ backgroundColor: '#f6f8fc', borderBottom: '1px solid #dadce0' }}>
                                    <h6 className="modal-title text-muted mb-0">New Message</h6>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                                </div>

                                <div className="modal-body p-0">
                                    <div className="p-3">
                                        <div className="mb-2 d-flex align-items-center">
                                            <label className="text-muted small me-2" style={{ width: '30px' }}>To</label>
                                            <input
                                                type="email"
                                                name="to"
                                                className="form-control border-0 p-1"
                                                value={formData.to}
                                                onChange={handleInputChange}
                                                style={{ boxShadow: 'none' }}
                                            />
                                        </div>
                                        <div className="mb-2 d-flex align-items-center">
                                            <label className="text-muted small me-2" style={{ width: '30px' }}>Cc</label>
                                            <input
                                                type="email"
                                                name="cc"
                                                className="form-control border-0 p-1"
                                                value={formData.cc}
                                                onChange={handleInputChange}
                                                style={{ boxShadow: 'none' }}
                                            />
                                        </div>
                                        <div className="mb-2 d-flex align-items-center">
                                            <label className="text-muted small me-2" style={{ width: '30px' }}>Bcc</label>
                                            <input
                                                type="email"
                                                name="bcc"
                                                className="form-control border-0 p-1"
                                                value={formData.bcc}
                                                onChange={handleInputChange}
                                                style={{ boxShadow: 'none' }}
                                            />
                                        </div>
                                        <div className="mb-2 d-flex align-items-center">
                                            <label className="text-muted small me-2" style={{ width: '60px' }}>Subject</label>
                                            <input
                                                type="text"
                                                name="subject"
                                                className="form-control border-0 p-1"
                                                value={formData.subject}
                                                onChange={handleInputChange}
                                                style={{ boxShadow: 'none' }}
                                            />
                                        </div>

                                        <hr className="my-2" style={{ borderColor: '#dadce0' }} />

                                        <RichTextEditor
                                            content={formData.message}
                                            onChange={handleMessageChange}
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer py-2 px-3" style={{ backgroundColor: '#f6f8fc', borderTop: '1px solid #dadce0' }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary px-4"
                                        // onClick={handleSendFeedback}
                                        disabled={sending}
                                    >
                                        {sending ? 'Sending...' : 'Send'}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-link text-muted"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <X size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )}
        </>
    );
};

export default FeedbackButton;
