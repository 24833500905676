import { axiosInstance } from "../utills";

export const fetchLeadsApi = async () => {
    const config = {
        method: "get",
        url: "/get_leads",
    };
    const response = await axiosInstance.request(config);
    return response.data;
};

export const saveNote = async (leadId, phoneNumber, note) => {
    const config = {
        method: "post",
        url: "/create-note",
        data: {
            phone_number: phoneNumber,
            note: note,
        },
    };
    const response = await axiosInstance.request(config);
    return response.data;
};

export const updateLeadStatus = async (leadId, newStatus) => {
    const config = {
        method: "post",
        url: "/update_lead_status",
        data: {
            lead_id: leadId,
            lead_status: newStatus,
        },
    };
    const response = await axiosInstance.request(config);
    return response.data;
};

export const deleteLeads = async (leadIds) => {
    const config = {
        method: "post",
        url: "/delete_leads",
        data: {
            lead_ids: leadIds,
        },
    };
    const response = await axiosInstance.request(config);
    return response.data;
};

export const fetchSummary = async (phoneNumber) => {
    const config = {
        method: "post",
        url: "/get_summary",
        data: {
            phone_number: phoneNumber,
        },
    };
    const response = await axiosInstance.request(config);
    return response.data;
};
