import React, { useState } from "react";
import Logout from "../Logout";
import aisms from "../../assets/aisms.png";

import Two from "../../assets/2.svg";
import Six from "../../assets/6.svg";
import Recycle from "../../assets/recycle.svg";
import Settings from "../../assets/settings.svg";
import Lead from "../../assets/lead.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_white_freedom.png";
import Zoom from "../../assets/zoom.svg";
import "./SidebarDesktop.css";
import "../ui-components/icnImage.css";

const SidebarDesktop = ({ screenWidth }) => {
    const [showAISMSSubmenu, setShowAISMSSubmenu] = useState(false);

    const toggleAISMSSubmenu = () => {
        setShowAISMSSubmenu(!showAISMSSubmenu);
    };

    return (
        <div
            className="side-menu"
            style={{ display: screenWidth < 630 && "none" }}
        >
            <div className="top-holder">
                <center>
                    <img src={Logo} alt="Logo" className="logo" />
                </center>
            </div>
            <div className="middle-holder">
                {/* AI SMS Main Menu */}
                <span onClick={toggleAISMSSubmenu} className="menu-item aisms">
                <img src={aisms} alt="AiSms" className="icn" />

                    AI SMS
                </span>
                {/* AI SMS Submenu */}
                {showAISMSSubmenu && (
                    <div className="submenu ms-3">
                        <span>
                            <Link to="./">
                                <img src={Two} alt="Analysis" className="icn" />
                                Analysis
                            </Link>
                        </span>
                        <span>
                            <Link to="./users">
                                <img src={Six} alt="Contacts" className="icn" />
                                Contacts
                            </Link>
                        </span>
                        <span>
                            <Link to="./inbox">
                                <img src={Recycle} alt="Inbox" className="icn" />
                                Inbox
                            </Link>
                        </span>
                        <span>
                            <Link to="./leads">
                                <img src={Lead} alt="Leads" className="icn" />
                                Leads
                            </Link>
                        </span>
                    </div>
                )}

                {/* Other Menu Items */}
                <span>
                    <Link to="./zoom-analysis">
                        <img src={Zoom} alt="Zoom Analysis" className="icn" />
                        Zoom Analysis
                    </Link>
                </span>
                <span>
                    <Link to="./settings">
                        <img src={Settings} alt="Settings" className="icn" />
                        Settings
                    </Link>
                </span>
            </div>

            <Logout />
        </div>
    );
};

export default SidebarDesktop;
