// File: /components/charts/UserActivityChart.js
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "./Charts.module.css"
import "../ui-components/Card.css"

const UserActivityChart = ({ userActivityData, handleActivityPeriodChange, activityPeriod }) => {
      const [colClass, setColClass] = useState("col-md-8");
    
      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 1284 && window.innerWidth >= 996) {
            setColClass("col-md-6");
          } else if(window.innerWidth < 996){
            setColClass("col-md-12");
          }
          else{
            setColClass("col-md-8");

          }
        };
    
        window.addEventListener("resize", handleResize);
        handleResize(); // Trigger initially
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    return (
        <div
            className={`${colClass} ${styles.container}`}

        >
            <div
                className={`dashboard-card ${styles.card}`}
            >
                <div
                    className={` ${styles.flexContainerPrimary}`}

                >
                    <h5 className={` ${styles.heading}`}>
                        User Activity Over Time
                    </h5>
                    <select
                        className="form-control"
                        style={{ width: "auto" }}
                        value={activityPeriod}
                        onChange={handleActivityPeriodChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <Line
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Period",
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: "Active Users",
                                },
                            },
                        },
                    }}
                    data={{
                        labels: userActivityData.map(({ period }) => period),
                        datasets: [
                            {
                                fill: true,
                                label: "Active Users",
                                data: userActivityData.map(
                                    ({ active_users }) => active_users
                                ),
                                borderColor: "rgb(31, 154, 214)",
                                backgroundColor: "rgba(31, 154, 214, 0.5)",
                            },
                        ],
                    }}
                />
            </div>
        </div>
    );
};

export default UserActivityChart;