import React, { useState, useEffect, useRef } from "react";

import { deleteLeads, fetchLeadsApi, fetchSummary, saveNote, updateLeadStatus } from "../../api/leadsApi";
import PaginationControl from "../../component/PaginationControl";
import "./Lead.css"
import LeadTable from "../../component/lead-components/LeadTable";
import LeadModal from "../../component/lead-components/LeadModal";
import { LeadFormatDate } from "../../component/lead-components/LeadFormatDate";
import usePagination from "../../hooks/usePagination";
import LeadFilter from "../../component/lead-components/LeadFilter";
function Leads() {
  const [leadsData, setLeadsData] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [filterOption, setFilterOption] = useState("All");
  const [notes, setNotes] = useState({});
  const [isUpdating, setIsUpdating] = useState(null);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  // New state for selected leads
  const [selectedLeads, setSelectedLeads] = useState([]);
  const headerCheckboxRef = useRef(null);

  // New states for Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // New state for saving notes
  const [isSaving, setIsSaving] = useState({});

  // Fetch leads data from the API
  const fetchLeads = async () => {
    try {
      const response = await fetchLeadsApi()
      console.log("Leads Data is:", response);
      setLeadsData(response);

      // Initialize notes state with existing notes from leadsData
      const initialNotes = response.reduce((acc, lead) => {
        acc[lead.id] = lead.notes || "";
        return acc;
      }, {});
      setNotes(initialNotes);
    } catch (error) {
      console.error("Error fetching leads data:", error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    filterLeads();
  }, [leadsData, filterOption]);

  const filterLeads = () => {
    let filtered = [];

    const currentDate = new Date();

    if (filterOption === "All") {
      filtered = leadsData;
    } else if (filterOption === "Upcoming") {
      filtered = leadsData.filter((lead) => {
        return (
          lead.appointment_date && new Date(lead.appointment_date) > currentDate
        );
      });
    } else if (filterOption === "Past Appointments") {
      filtered = leadsData.filter((lead) => {
        return (
          lead.appointment_date && new Date(lead.appointment_date) < currentDate
        );
      });
    }

    setFilteredLeads(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
    setSelectedLeads([]);
  };

  const formatDate = (dateString) => {
    return LeadFormatDate(dateString);
  };

  const handleNoteChange = (e, leadId) => {
    setNotes({
      ...notes,
      [leadId]: e.target.value,
    });
  };

  const handleSaveNote = async (leadId) => {
    const note = notes[leadId] || "";
    const lead = leadsData.find((lead) => lead.id === leadId);
    if (!lead) {
      alert("Lead not found.");
      return;
    }

    // Set saving state for this lead
    setIsSaving((prev) => ({ ...prev, [leadId]: true }));

    try {


      const response = await saveNote(leadId, lead.phone_number, note)
      console.log(`Note saved for lead ${leadId}:`, response.message);

      // Optionally, provide user feedback
      alert("Note saved successfully.");

      // Optionally, update leadsData with the new note
      const updatedLeads = leadsData.map((l) =>
        l.id === leadId ? { ...l, notes: note } : l
      );
      setLeadsData(updatedLeads);
    } catch (error) {
      console.error("Error saving note:", error);
      if (error.response && error.response && error.response.error) {
        alert(`Error: ${error.response.error}`);
      } else {
        alert("Failed to save note. Please try again.");
      }
    } finally {
      // Reset saving state for this lead
      setIsSaving((prev) => ({ ...prev, [leadId]: false }));
    }
  };

  

  usePagination(filteredLeads, currentPage, itemsPerPage, setStartIndex, setEndIndex, setPaginatedData, setTotalPages);

  // Handle lead status change and call API with loading and error handling
  const handleLeadStatusChange = async (leadId, newStatus) => {
    setIsUpdating(leadId); // Set the lead as updating to show a spinner

    const lead = leadsData.find((lead) => lead.id === leadId);
    if (!lead) {
      alert("Lead not found.");
      setIsUpdating(null);
      return;
    }



    try {
      const response = await updateLeadStatus(leadId, newStatus)
      console.log("Lead status updated:", response);

      // Update the lead status locally in the state
      const updatedLeads = leadsData.map((lead) =>
        lead.id === leadId ? { ...lead, lead_status: newStatus } : lead
      );
      setLeadsData(updatedLeads);
    } catch (error) {
      console.error("Error updating lead status:", error);
      alert("Failed to update lead status. Please try again."); // Display an error message
    } finally {
      setIsUpdating(null); // Reset the updating state
    }
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (leadId) => {
    setSelectedLeads((prevSelectedLeads) => {
      if (prevSelectedLeads.includes(leadId)) {
        // Remove the leadId from selectedLeads
        return prevSelectedLeads.filter((id) => id !== leadId);
      } else {
        // Add the leadId to selectedLeads
        return [...prevSelectedLeads, leadId];
      }
    });
  };

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectedLeads.length === filteredLeads.length) {
      // All are selected, so unselect all
      setSelectedLeads([]);
    } else {
      // Select all lead IDs
      setSelectedLeads(filteredLeads.map((lead) => lead.id));
    }
  };

  // Update indeterminate state of header checkbox
  useEffect(() => {
    if (headerCheckboxRef.current) {
      headerCheckboxRef.current.indeterminate =
        selectedLeads.length > 0 && selectedLeads.length < filteredLeads.length;
    }
  }, [selectedLeads]);

  // Handle delete selected leads
  const handleDeleteSelectedLeads = async () => {
    if (selectedLeads.length === 0) return;

    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedLeads.length} leads?`
    );
    if (!confirmDelete) return;

    try {

      const response = await deleteLeads(selectedLeads)
      console.log("Delete response:", response);

      // Fetch leads data again
      await fetchLeads();

      // Clear selectedLeads
      setSelectedLeads([]);
    } catch (error) {
      console.error("Error deleting leads:", error);
      alert("Failed to delete leads. Please try again.");
    }
  };

  // Function to open the modal and fetch summary
  const handleViewSummary = async (lead) => {
    setShowModal(true);
    setSelectedLead(lead);
    setSummary("");
    setError("");
    setLoading(true);

    try {

      const response = await fetchSummary(lead.phone_number)
      console.log("Summary Response:", response);

      if (response.summary) {
        setSummary(response.summary);
      } else if (response.Message) {
        setSummary(response.Message);
      } else {
        setError("No summary available.");
      }
    } catch (error) {
      console.error("Error fetching summary:", error);
      setError("Failed to fetch summary. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedLead(null);
    setSummary("");
    setError("");
    setLoading(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#FFA500"; // Orange for Pending
      case "Rescheduled":
        return "#FF6347"; // Tomato for Rescheduled
      case "Completed":
        return "#04AA6D"; // Green for Completed
      default:
        return "white"; // Default white color
    }
  };

  return (
    <>
      {/* Modal */}
      {showModal && (
        <LeadModal
          closeModal={closeModal}
          summary={summary}
          loading={loading}
          error={error}
        />)}
       <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          justifyContent: "space-between",
        }}
      >
        <LeadFilter setFilterOption={setFilterOption} filterOption={filterOption}/>
&nbsp;&nbsp;
        {selectedLeads.length > 0 && (
          <button
            onClick={handleDeleteSelectedLeads}
            className="btn btn-danger btn-sm"
          >
            Delete Appointment
          </button>
        )}
      </div>
      <LeadTable
        headerCheckboxRef={headerCheckboxRef}
        selectedLeads={selectedLeads}
        filteredLeads={filteredLeads}
        handleSelectAll={handleSelectAll}
        paginatedData={paginatedData}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        formatDate={formatDate}
        handleCheckboxChange={handleCheckboxChange}
        handleLeadStatusChange={handleLeadStatusChange}
        getStatusColor={getStatusColor}
        isUpdating={isUpdating}
        handleViewSummary={handleViewSummary}
        notes={notes}
        handleNoteChange={handleNoteChange}
        handleSaveNote={handleSaveNote}
        isSaving={isSaving}
      />
      {/* Pagination Controls */}
      <PaginationControl
        currentPage={currentPage}
        totalPages={totalPages}
        tempPage={tempPage}
        setCurrentPage={setCurrentPage}
        updateTempPage={updateTempPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
     
      />
    </>
  );
}

export default Leads;
