import {

    Link as LinkIcon,
    User,
    Mail,
    Users,
    Clock,
    Video,
    Calendar,
    Briefcase,

} from "lucide-react";
import { formatDistanceToNow, format } from "date-fns";

const SessionVideo = ({ session }) => {
    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="row g-4">
                    {/* Left Column */}
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-3">
                            <User className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Representative</div>
                                <div className="fw-medium">{session.representative.name}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <Users className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Role</div>
                                <div className="fw-medium">{session.representative.role}</div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <Mail className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Email</div>
                                <div className="fw-medium">{session.representative.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-3">

                            <Users className="text-primary me-2" size={20} />
                            <div>
                            <div className="text-muted small">Participants</div>

                                <div className="fw-medium">
                                    {session.participants.map((p) => p.name).join(", ")}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <Clock className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Duration</div>
                                <div className="fw-medium">
                                    {session.duration} minutes</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <Calendar className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Session Date:</div>
                                <div className="fw-medium">
                                {format(new Date(session.date), "do MMMM, yyyy")}

                                    (
                                    {formatDistanceToNow(new Date(session.date), {
                                        addSuffix: true,
                                    })}
                                    )
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SessionVideo