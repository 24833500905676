import { FlagSection } from "../FlagSection"

const SessionFlags = ({ session }) => {
    return (
        <div
            className="col-md-12 bg-white rounded-xl shadow-sm mb-8 card"
            style={{ padding: 16 }}
        >
            <h4
                className="text-xl font-semibold text-gray-900 mb-4"
                style={{ fontFamily: "system-ui" }}
            >
                Flags During The Session
            </h4>
            <FlagSection flags={session.flags} />
        </div>
    )
}

export default SessionFlags