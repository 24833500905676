export const LeadFormatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);

    // Arrays for day and month names
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Extract UTC components to avoid timezone conversion
    const dayOfWeek = dayNames[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    // Get UTC hours and minutes
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Convert to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Add leading zeros to hours and minutes
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");

    // Construct the time string
    const timeStr = `${hoursStr}:${minutesStr} ${ampm}`;

    // Construct the final formatted date string
    return `${dayOfWeek}, ${day} ${month} ${year}, ${timeStr}`;
};
