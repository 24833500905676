// File: /api/dashboardApi.js
import { axiosInstance } from "../utills";

export const fetchBroadcastChannels = async () => {
    const config = {
        method: "get",
        url: "/get-broadcast-channels",
    };
    return axiosInstance.request(config).then((response) => response.data);
};

export const fetchKpiStats = async (broadcastId) => {
    const config = {
        method: "post",
        url: "/get-kpi-stats",
        data: { broadcastId },
    };
    return axiosInstance.request(config).then((response) => response.data);
};

export const fetchUserActivityOverTime = async (period) => {
    const config = {
        method: "get",
        url: "/user_activity_over_time",
        params: { period },
    };
    return axiosInstance.request(config).then((response) => response.data.data);
};

export const fetchLeadStatusDistribution = async () => {
    const config = {
        method: "get",
        url: "/lead_status_distribution",
    };
    return axiosInstance.request(config).then((response) => {
        const leadData = response.data.data;
        return leadData.filter((item) => item.lead_status !== "Unknown");
    });
};

export const fetchAppointmentTrendsData = async (period) => {
    const config = {
        method: "get",
        url: "/appointment_trends",
        params: { period },
    };
    return axiosInstance.request(config).then((response) => response.data.data);
};

export const fetchResponseTimeDistribution = async () => {
    const config = {
        method: "get",
        url: "/response_time_distribution",
    };
    return axiosInstance.request(config).then((response) => response.data.data);
};

export const fetchMessageVolumeOverTime = async (period) => {
    const config = {
        method: "get",
        url: "/message_volume_over_time",
        params: { period },
    };
    return axiosInstance.request(config).then((response) => response.data.data);
};

export const fetchUserCountGraphData = async () => {
    const config = {
        method: "get",
        url: "/dashboard/graph/get-graph-user-data",
    };
    return axiosInstance.request(config).then((response) =>
        response.data.data.monthWiseUserCounts
    );
};

export const fetchDoctorCountGraphData = async () => {
    const config = {
        method: "get",
        url: "/dashboard/graph/get-graph-doctors-data",
    };
    return axiosInstance.request(config).then((response) =>
        response.data.data.monthWiseUserCounts
    );
};

export const fetchBookedAppointmentGraphData = async () => {
    const config = {
        method: "get",
        url: "/dashboard/graph/get-graph-appointment-data",
    };
    return axiosInstance.request(config).then((response) =>
        response.data.data.monthWiseAppointmentCounts
    );
};
