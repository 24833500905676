import { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { baseURL } from "../utills";

const useSocket = () => {
    const [trigger, setTrigger] = useState("");

    useEffect(() => {
        const socket = socketIOClient(baseURL, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
        });

        socket.on("message", (data) => {
            console.log("Socket Connected and Message Received");
            setTrigger(data);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return trigger;
};

export default useSocket;
