import Two from "../../assets/2.svg";
import Six from "../../assets/6.svg";
import Recycle from "../../assets/recycle.svg";
import Settings from "../../assets/settings.svg";
import Lead from "../../assets/lead.svg";
import { Link } from "react-router-dom";
import Zoom from "../../assets/zoom.svg";
import Seven from "../../assets/7.svg";
import aisms from "../../assets/aisms.png";

import styles from "./SidebarMobile.module.css";
import "../ui-components/icnImage.css"
import { useState } from "react";

const SidebarMobile = ({ setIsMenuOpen }) => {
    const [showAISMSSubmenu, setShowAISMSSubmenu] = useState(false);

    const toggleAISMSSubmenu = () => {
        setShowAISMSSubmenu(!showAISMSSubmenu);
    };
    return (
        <div className={styles.mobileMenuOverlay}>
            <div className={styles.mobileMenu}>
                <button className={styles.closeButton} onClick={() => setIsMenuOpen(false)}>
                    &times;
                </button>
                <div className={styles.mobileMenuLinks} >
                    <div className={styles.navMobileList}>
                    <Link onClick={toggleAISMSSubmenu} className={`${styles.menuItem}`}>
                    <img src={aisms} alt="Dashboard" className="icn" />

                            AI SMS
                        </Link>
                        {showAISMSSubmenu && (
                            <div className="ms-3">
                        <Link to="./" onClick={() => setIsMenuOpen(false)}>
                            <img src={Two} alt="Dashboard" className="icn" />
                            Analytics
                        </Link>
                        <Link to="./users" onClick={() => setIsMenuOpen(false)}>
                            <img src={Six} alt="User" className="icn" />
                            Contacts
                        </Link>
                        <Link to="./inbox" onClick={() => setIsMenuOpen(false)}>
                            <img src={Recycle} alt="Recycle" className="" />
                            Inbox
                        </Link>
                        <Link to="./leads" onClick={() => setIsMenuOpen(false)}>
                            <img src={Lead} alt="Leads" className="icn" />
                            Leads
                        </Link>
                        </div>
                        )}
                        <Link to="./zoom-analysis" onClick={() => setIsMenuOpen(false)}>
                            <img src={Zoom} alt="Zoom Analysis" className="icn" />
                            Zoom Analysis
                        </Link>
                        <Link to="./settings" onClick={() => setIsMenuOpen(false)}>
                            <img src={Settings} alt="Settings" className="icn" />
                            Settings
                        </Link>
                    </div>
                    <span
                        onClick={() => {
                            // Implement logout functionality here
                            window.location.href = "/";
                        }}
                        className={styles.logoutLink}
                    >
                        Logout&nbsp;<img src={Seven} alt="Logout" className="icn-2" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SidebarMobile