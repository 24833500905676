// hooks/useFetchData.js
import { useEffect } from "react";

const useFetchData = (fetchFn, setData) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchFn();
                setData(data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [fetchFn, setData]);
};

export default useFetchData;
