import "../ui-components/Table.css"

const UserTable = ({
    columns,
    tableData,
    filteredTableData,
    paginatedFilteredData,
    paginatedData,
    isCheckedAll,
    selectedUsers,
    setSelectedUsers,
    setIsCheckedAll,
}) => {


    console.log("Paginated Filtered Data:", paginatedFilteredData);
console.log("Paginated Data:", paginatedData);
console.log("Filtered Table Data:", filteredTableData);

    return(
    <div
        style={{
            padding: 12,
            borderRadius: 5,
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.2)",
            marginTop: 12,
        }}
    >
                    <div style={{ overflowX: "auto" }}>

        <table className="table" style={{ backgroundColor: "white" }}>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th style={{ fontSize: 12 }} key={index}>
                            {index === 0 ? (
                                <input
                                    type="checkbox"
                                    checked={isCheckedAll}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            const dataToSelect =
                                                filteredTableData.length > 0
                                                    ? filteredTableData
                                                    : tableData;
                                            setSelectedUsers(
                                                dataToSelect.map((item) => item.Select)
                                            );
                                        } else {
                                            setSelectedUsers([]);
                                        }
                                        setIsCheckedAll(e.target.checked);
                                    }}
                                />
                            ) : (
                                column
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {paginatedFilteredData.length > 0
                    ? paginatedFilteredData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => {
                                return (
                                    <td key={colIndex} style={{ fontFamily: "arial" }}>
                                        {colIndex === 0 ? (
                                            <input
                                                type="checkbox"
                                                checked={selectedUsers.includes(row.Select)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedUsers([
                                                            ...selectedUsers,
                                                            row.Select,
                                                        ]);
                                                    } else {
                                                        setSelectedUsers(
                                                            selectedUsers.filter(
                                                                (id) => id !== row.Select
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        ) : (
                                            row[column]
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))
                    : paginatedData?.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => {
                                return (
                                    <td key={colIndex} style={{ fontFamily: "arial" }}>
                                        {colIndex === 0 ? (
                                            <input
                                                type="checkbox"
                                                checked={selectedUsers.includes(row.Select)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedUsers([
                                                            ...selectedUsers,
                                                            row.Select,
                                                        ]);
                                                    } else {
                                                        setSelectedUsers(
                                                            selectedUsers.filter(
                                                                (id) => id !== row.Select
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        ) : (
                                            row[column]
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
            </tbody>
        </table>
        </div>
    </div>
    )
}

export default UserTable