import { axiosInstance } from "../utills";

export const getChatListApi = async () => {
    try {
        const response = await axiosInstance.get("/get-chat-list");
        return response.data;
    } catch (error) {
        console.error("Error fetching chat list:", error);
        throw error;
    }
};

export const deleteChat = async (type, value) => {
    try {
        const encodedValue = type === "solo" ? encodeURIComponent(value) : value;
        const response = await axiosInstance.get(`/delete-chat?type=${type}&value=${encodedValue}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting chat:", error);
        throw error;
    }
};
