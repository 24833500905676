import React from 'react';
import { Filter } from 'lucide-react';

export const ScorecardFilters = ({
    fromDate,
    toDate,
    selectedRepresentative,
    selectedRole,
    selectedRepresentatives,
    onFromDateChange,
    onToDateChange,
    onRepresentativeChange,
    onRoleChange,
    onRepresentativesChange,
    representatives,
    roles,
}) => {
    const handleRepresentativeSelect = (index, rep) => {
        const newReps = [...selectedRepresentatives];
        newReps[index] = rep;
        onRepresentativesChange(newReps);
    };

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                    <Filter className="text-primary me-2" size={20} />
                    <h6 className="mb-0">Filter Analytics</h6>
                </div>
                <div className="row g-3">
                    <div className="col-md-6 col-lg-3">
                        <label className="form-label">From Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={fromDate}
                            onChange={(e) => onFromDateChange(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label className="form-label">To Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={toDate}
                            onChange={(e) => onToDateChange(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label className="form-label">Representative</label>
                        <select
                            className="form-select"
                            value={selectedRepresentative}
                            onChange={(e) => onRepresentativeChange(e.target.value)}
                        >
                            <option value="">All Representatives</option>
                            {representatives.map((rep) => (
                                <option key={rep} value={rep}>
                                    {rep}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label className="form-label">Role</label>
                        <select
                            className="form-select"
                            value={selectedRole}
                            onChange={(e) => onRoleChange(e.target.value)}
                        >
                            <option value="">All Roles</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>
                                    {role}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label className="form-label">Compare Representatives</label>
                        <div className="d-flex gap-2">
                            {[0, 1].map((index) => (
                                <select
                                    key={index}
                                    className="form-select"
                                    value={selectedRepresentatives[index] || ''}
                                    onChange={(e) => handleRepresentativeSelect(index, e.target.value)}
                                    style={{ maxWidth: '200px' }}
                                >
                                    <option value="">Select Representative {index + 1}</option>
                                    {representatives.map((rep) => (
                                        <option key={rep} value={rep}>
                                            {rep}
                                        </option>
                                    ))}
                                </select>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
