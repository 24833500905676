import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "./Charts.module.css"
import "../ui-components/Card.css"

const MessageVolumeChart = ({ messageVolumePeriod, handleMessageVolumePeriodChange, messageVolumeData }) => {
      const [colClass, setColClass] = useState("col-md-4");
        
          useEffect(() => {
            const handleResize = () => {
              if (window.innerWidth < 1284) {
                setColClass("col-md-12 mt-3");
              } else {
                setColClass("col-md-4");
              }
            };
        
            window.addEventListener("resize", handleResize);
            handleResize(); // Trigger initially
        
            return () => {
              window.removeEventListener("resize", handleResize);
            };
          }, []);
    return (
        <div
            className={`${colClass}  ${styles.container}`}

        >
            <div
                className={`dashboard-card ${styles.card}`}
            >
                <div
                    className={` ${styles.flexContainerPrimary}`}

                >
                    <h5 className={` ${styles.heading}`}>
                        Message Volume Over Time
                    </h5>
                    <select
                        className="form-control"
                        style={{ width: "auto" }}
                        value={messageVolumePeriod}
                        onChange={handleMessageVolumePeriodChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <Line
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Period",
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: "Message Count",
                                },
                            },
                        },
                    }}
                    data={{
                        labels: messageVolumeData.map(({ period }) => period),
                        datasets: [
                            {
                                fill: true,
                                label: "Messages",
                                data: messageVolumeData.map(
                                    ({ message_count }) => message_count
                                ),
                                borderColor: "rgb(255, 159, 64)",
                                backgroundColor: "rgba(255, 159, 64, 0.5)",
                            },
                        ],
                    }}
                />
            </div>
        </div>
    )

}
export default MessageVolumeChart