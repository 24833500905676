import React, { useEffect, useState } from "react";
import styles from './DeleteData.module.css';
import { deleteUsers } from "../../api/userApi";

function DeteletData({
  selectedUsers,
  setSelectedUsers,
  userDataUpdate,
  setCurrentPage,
  updateTempPage,
}) {
  const [deleting, updateDeleting] = useState(false);

  useEffect(() => {
    console.log("Delete Data: Selected Users->", selectedUsers.join(", "));
  }, [selectedUsers]);

  const handleDelete = async () => {
    updateDeleting(true);
    try {
      const response = await deleteUsers(selectedUsers)
      console.log("response.data: ", response.data);
      if (response.status === 200 || response.status === 201) {
        updateDeleting(false);
        userDataUpdate(true);
        setSelectedUsers([]);
        setCurrentPage(1);
        updateTempPage(1);
        alert("Users Deleted Successfully!");
      }
      updateDeleting(false);
      userDataUpdate(true);
      setSelectedUsers([]);
      setCurrentPage(1);
      updateTempPage(1);
    }
    catch (error) {
      console.log(error);
      updateDeleting(false);
      userDataUpdate(true);
      setSelectedUsers([]);
      setCurrentPage(1);
      updateTempPage(1);
      alert("Error! Try again later.");
    };
  };

  return (
    <>
      {selectedUsers.length > 0 &&
        (deleting ? (
          <button
            className={`btn ${styles.tomatoButton}`}

            disabled={true}
          >
            Deleting...
          </button>
        ) : (
          <button
            className={`btn ${styles.tomatoButton}`}

            onClick={() => handleDelete()}
          >
            Delete Users
          </button>
        ))}
    </>
  );
}

export default DeteletData;
